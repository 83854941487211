import $ from 'jquery'
import 'slick-carousel'
import AOS from 'aos';
import Swal from 'sweetalert2'
import './mini-cart';

import 'aos/dist/aos.css';
import 'sweetalert2/src/sweetalert2.scss'

const coreHeader = $("#core-header");

/***************************************
 * AOS Effect
 ***************************************/
AOS.init({
  duration: 750, easing: 'ease', offset: -100, once: true,
});

const hamburger = $('#hamburger');
const mainNavigation = $('#main-navigation');

hamburger.click(function () {
  $(this).toggleClass('active');
  mainNavigation.toggleClass('show');

  if ($(this).hasClass('active')) {
    body.addClass('no-scroll');
  } else {
    body.removeClass('no-scroll');
  }
})

/***************************************
 * AOS Effect
 ***************************************/
const actionEvent = typeof action !== 'undefined' ? action : false;

if (actionEvent) {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  });

  // Login notification
  if (actionEvent === 'login') {
    Toast.fire({
      icon: "success",
      title: "Succesvol ingelogd!"
    }).then(r => {
      // Nothing
    });
  }
  // Logout notification
  if (actionEvent === 'logout') {
    Toast.fire({
      icon: "success",
      title: "Succesvol uitgelogd!"
    }).then(r => {
      // Nothing
    });
  }
}

/***************************************
 * Mega menu
 ***************************************/
const megaMenuItem = $('.mega-menu-hover');
const megaMenu = $('.mega-menu')
const megaMenuBackdrop = $('.mega-menu--backdrop');
const body = $('body, html');

let menuTimeout;

megaMenuItem.add(megaMenu).on('mouseenter', function () {
  clearTimeout(menuTimeout);
  megaMenu.addClass('show');
  megaMenuBackdrop.addClass('show');
  coreHeader.addClass('no-box-shadow');
  body.addClass('no-scroll');
})
megaMenuItem.add(megaMenu).on('mouseleave', function (e) {
  if (!$(e.relatedTarget).closest('.mega-menu-hover, .mega-menu').length) {

    menuTimeout = setTimeout(function () {
      megaMenu.removeClass('show');
      megaMenuBackdrop.removeClass('show');
      coreHeader.removeClass('no-box-shadow');
      body.removeClass('no-scroll');
    }, 200);
  }
});

/***************************************
 * Mega menu items
 ***************************************/

const megaMenuMainItems = $('.main-items li');
const megaMenuMenus = $('.mega-menu .menu')

megaMenuMainItems.hover(function () {
  let menu = $($(this).data('hover'));

  // Toggle menu item classes
  megaMenuMainItems.not($(this)).removeClass('active');
  $(this).addClass('active');

  // Toggle menu classes
  megaMenuMenus.not(menu).removeClass('show');
  menu.addClass('show');
})


/***************************************
 * Sliders
 ***************************************/

$('.header-slider').slick({
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: false,
  arrows: false,
});

$('#new-products-slider, #most-sold-products-slider, #logo-slider').slick({
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  dots: false,
  arrows: true,
  prevArrow: '<div class="prev"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M165.66,202.34a8,8,0,0,1-11.32,11.32l-80-80a8,8,0,0,1,0-11.32l80-80a8,8,0,0,1,11.32,11.32L91.31,128Z"></path></svg></div>',
  nextArrow: '<div class="next"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256">\n' +
    '    <path\n' +
    '      d="M181.66,133.66l-80,80a8,8,0,0,1-11.32-11.32L164.69,128,90.34,53.66a8,8,0,0,1,11.32-11.32l80,80A8,8,0,0,1,181.66,133.66Z"></path>\n' +
    '  </svg></div>',
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      }
    }
  ]
});

/***************************************
 * Scroll function
 ***************************************/

myNavOnScroll();

$(window).on('scroll', myNavOnScroll);

function myNavOnScroll() {
  let scrollPosition = Math.round(window.scrollY);

  if (scrollPosition > 2) {
    coreHeader.addClass("sticky");
    megaMenu.addClass("sticky");
  } else {
    coreHeader.removeClass("sticky");
    megaMenu.removeClass("sticky");
  }
}


/***************************************
 * Search products
 ***************************************/

const searchBar = $('#search');
const searchBarBox = $('.search-form--box');
const openSearchButton = $('#open-search');
const headerBox = $('.header--box');
const headerBoxRight = $('.header--box--right')
const searchResults = $('#search-results');
const resetSearchButton = $('#reset-search');
const resetSearch = $('#reset-search, #search-backdrop');
const searchBackdrop = $('#search-backdrop');

openSearchButton.click(function () {
  searchBar.focus();
  searchBarBox.addClass('show');
})

searchBar.on("focus", function () {
  coreHeader.addClass('search-active');
  searchBar.addClass('active');
  headerBoxRight.add(headerBox).addClass('search-active');
  searchBackdrop.addClass('show');

  const value = $(this).val()
  if (value !== '') {
    checkSearchBar(value);
  }
});
searchBar.on("focus, change, keyup", function () {
  checkSearchBar($(this).val())

});

function checkSearchBar(searchValue = '') {

  if (searchValue !== '') {
    coreHeader.addClass('search-active');
    searchBar.addClass('active');
    headerBoxRight.add(headerBox).addClass('search-active');
    body.addClass('no-scroll');
    searchBackdrop.addClass('show');
    resetSearchButton.show();

    searchModels(searchValue);

  } else {
    coreHeader.removeClass('search-active');
    searchBar.removeClass('active');
    headerBoxRight.add(headerBox).removeClass('search-active');
    searchResults.removeClass('active').empty().hide();
    body.removeClass('no-scroll');
    searchBackdrop.removeClass('show');
    resetSearchButton.hide();
  }
}

// Reset search by reset button and backdrop
resetSearch.click(function () {

  checkSearchBar();
})


function searchModels(searchValue) {
  $.ajax({
    url: '/actions/search-module/search/get-search-results',
    type: 'GET',
    data: {
      search: searchValue
    },
    success: function (results) {

      searchResults.addClass('active').empty();

      if (results.products && results.products.length > 0) {
        if (results['productCount'] > 6) {
          searchResults.append(`<div class="header"><h3>Producten</h3> <a class="all" href="/zoeken?search=${encodeURIComponent(searchValue)}" >Bekijk alle ${results['productCount']} producten </a></div><ul class="product-list"></ul>`);

        } else {
          searchResults.append('<div class="header"><h3>Producten</h3></div><ul class="product-list"></ul>');
        }

        results.products.forEach(product => {
          searchResults.find('.product-list').append(
            `<li><a href="${product.url}">${product.title} <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M181.66,133.66l-80,80a8,8,0,0,1-11.32-11.32L164.69,128,90.34,53.66a8,8,0,0,1,11.32-11.32l80,80A8,8,0,0,1,181.66,133.66Z"></path></svg></a></li>`
          );
        });
      }

      if (results.brands && results.brands.length > 0) {
        searchResults.append('<div class="header"><h3>Merken</h3></div><ul class="brand-list"></ul>');
        results.brands.forEach(brand => {
          searchResults.find('.brand-list').append(
            `<li><a href="${brand.url}">${brand.title} <svg class="search" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path
                        d="M17.9422 17.0575L14.0305 13.1466C15.1642 11.7854 15.7296 10.0395 15.6089 8.27214C15.4883 6.50475 14.6909 4.85192 13.3826 3.65748C12.0744 2.46304 10.356 1.81895 8.58493 1.8592C6.81388 1.89945 5.12653 2.62094 3.87389 3.87358C2.62125 5.12622 1.89976 6.81357 1.85951 8.58462C1.81926 10.3557 2.46335 12.074 3.65779 13.3823C4.85223 14.6906 6.50506 15.488 8.27245 15.6086C10.0398 15.7293 11.7857 15.1639 13.1469 14.0302L17.0578 17.9419C17.1159 17.9999 17.1848 18.046 17.2607 18.0774C17.3366 18.1089 17.4179 18.125 17.5 18.125C17.5821 18.125 17.6634 18.1089 17.7393 18.0774C17.8152 18.046 17.8841 17.9999 17.9422 17.9419C18.0003 17.8838 18.0463 17.8149 18.0777 17.739C18.1092 17.6631 18.1253 17.5818 18.1253 17.4997C18.1253 17.4176 18.1092 17.3363 18.0777 17.2604C18.0463 17.1845 18.0003 17.1156 17.9422 17.0575ZM3.125 8.74969C3.125 7.63717 3.4549 6.54963 4.07298 5.62461C4.69107 4.69958 5.56957 3.97861 6.59741 3.55287C7.62524 3.12712 8.75624 3.01573 9.84738 3.23277C10.9385 3.44981 11.9408 3.98554 12.7275 4.77221C13.5141 5.55888 14.0499 6.56116 14.2669 7.65231C14.484 8.74345 14.3726 9.87445 13.9468 10.9023C13.5211 11.9301 12.8001 12.8086 11.8751 13.4267C10.9501 14.0448 9.86252 14.3747 8.75 14.3747C7.25867 14.373 5.82889 13.7799 4.77435 12.7253C3.71982 11.6708 3.12666 10.241 3.125 8.74969Z"
                        fill="#423D34"/>
                    </svg></a></li>`
          );
        });
      }

      if (results.models && results.models.length > 0) {
        searchResults.append('<div class="header"><h3>Modellen</h3></div><ul class="model-list"></ul>');
        results.models.forEach(model => {
          searchResults.find('.model-list').append(
            `<li><a href="${model.url}">${model.title} <svg class="search" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path
                        d="M17.9422 17.0575L14.0305 13.1466C15.1642 11.7854 15.7296 10.0395 15.6089 8.27214C15.4883 6.50475 14.6909 4.85192 13.3826 3.65748C12.0744 2.46304 10.356 1.81895 8.58493 1.8592C6.81388 1.89945 5.12653 2.62094 3.87389 3.87358C2.62125 5.12622 1.89976 6.81357 1.85951 8.58462C1.81926 10.3557 2.46335 12.074 3.65779 13.3823C4.85223 14.6906 6.50506 15.488 8.27245 15.6086C10.0398 15.7293 11.7857 15.1639 13.1469 14.0302L17.0578 17.9419C17.1159 17.9999 17.1848 18.046 17.2607 18.0774C17.3366 18.1089 17.4179 18.125 17.5 18.125C17.5821 18.125 17.6634 18.1089 17.7393 18.0774C17.8152 18.046 17.8841 17.9999 17.9422 17.9419C18.0003 17.8838 18.0463 17.8149 18.0777 17.739C18.1092 17.6631 18.1253 17.5818 18.1253 17.4997C18.1253 17.4176 18.1092 17.3363 18.0777 17.2604C18.0463 17.1845 18.0003 17.1156 17.9422 17.0575ZM3.125 8.74969C3.125 7.63717 3.4549 6.54963 4.07298 5.62461C4.69107 4.69958 5.56957 3.97861 6.59741 3.55287C7.62524 3.12712 8.75624 3.01573 9.84738 3.23277C10.9385 3.44981 11.9408 3.98554 12.7275 4.77221C13.5141 5.55888 14.0499 6.56116 14.2669 7.65231C14.484 8.74345 14.3726 9.87445 13.9468 10.9023C13.5211 11.9301 12.8001 12.8086 11.8751 13.4267C10.9501 14.0448 9.86252 14.3747 8.75 14.3747C7.25867 14.373 5.82889 13.7799 4.77435 12.7253C3.71982 11.6708 3.12666 10.241 3.125 8.74969Z"
                        fill="#423D34"/>
                    </svg></a></li>`
          );
        });
      }

      if (results.products && results.products.length === 0 && results.models && results.models.length === 0 && results.brands && results.brands.length === 0) {
        searchResults.append('<ul><li><a href="#">Geen resultaten gevonden voor deze zoekopdracht</a></li></ul>');
      }

      searchResults.show();

      if (searchBar.val() === '') {
        checkSearchBar();
      }

    },
    error: function (xhr, status, error) {
      console.error('Error:', error);
      console.log(xhr.responseText);
    }
  });
}
