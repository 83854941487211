import $ from 'jquery'

const cart = $('#mini-cart');
const miniCart = $('.side-mini-cart');
let miniCartFooter = $('.side-mini-cart--footer');
let miniCartBody = $('.side-mini-cart--body');
let miniCartBodyItems = $('.side-mini-cart--body-items');
let miniCartEmpty = $('.side-mini-cart--empty');
const miniCartButton = $('.icon.mini-cart');
const miniCartCount = $('#cart-count');
const backdrop = $('.backdrop');
const closeCart = $('#close-cart')

miniCartButton.click(function () {
  toggleCart(true);
})

backdrop.click(function () {
  toggleCart(false);
  $('.modal').removeClass('show')
})

closeCart.click(function() {
  toggleCart(false);
})

$(document).on('keydown', function (event) {
  if (event.key === "Escape") {
    toggleCart(false);
    $('.modal').removeClass('show')

    $('#search').val('');
  }
})

/***************************************
 * Toggle for mini cart
 ***************************************/

function toggleCart($open = false) {
  if ($open) {
    cart.addClass('open');
    backdrop.addClass('show');

    $('.page').addClass('cart-open');
    $('html').addClass('no-scroll');
  } else {
    cart.removeClass('open');
    backdrop.removeClass('show');

    $('.page').removeClass('cart-open');
    $('html').removeClass('no-scroll');
  }
}

/***************************************
 * Quantity change in mini cart
 ***************************************/

$(document).ready(function () {
  // Handle the click event for plus and minus buttons
  $(document).on('click', '.qty-btn', function (e) {
    e.preventDefault();
    console.log('test');

    let lineItemId = $(this).data('line-item-id');
    let $form = $(this).closest('form.quantity-box');
    let $input = $form.find('input.qty-input');
    let currentQty = parseInt($input.val());

    if ($(this).hasClass('minus')) {
      if (currentQty > 1) {
        currentQty--;
      }
    } else if ($(this).hasClass('plus')) {
      currentQty++;
    }

    $input.val(currentQty);


    $form.submit();
  });

  /***************************************
   * Quantity ajax request
   ***************************************/

  $(document).on('submit', 'form.quantity-box', function (e) {
    e.preventDefault();

    const csrfTokenValue = $(this).find('input[name="CRAFT_CSRF_TOKEN"]').val();

    $.ajax({
      url: $(this).attr('action'),
      type: 'POST',
      data: $(this).serialize(),
      dataType: "json",
      headers: {
        'CRAFT_CSRF_TOKEN': csrfTokenValue
      },
      success: function (response) {
        const cart = response.cart ?? false;
        if (cart) {
          updateCart(cart);
        } else {
          console.log('Failed to update the cart.');
        }

      },
      error: function (xhr, status, error) {
        console.error('Error:', error);
      }
    });
  });

  /***************************************
   * Remove line item in cart
   ***************************************/
  $(document).on('submit', 'form.remove-item-form', function (e) {
    e.preventDefault();

    const csrfTokenValue = $(this).find('input[name="CRAFT_CSRF_TOKEN"]').val();

    if (confirm("Weet je zeker dat je dit product wilt verwijderen?") === true) {
      $.ajax({
        url: $(this).attr('action'),
        type: 'POST',
        data: $(this).serialize(),
        dataType: "json",
        headers: {
          'CRAFT_CSRF_TOKEN': csrfTokenValue
        },
        success: function (response) {
          const cart = response.cart ?? false;


          if (cart) {
            updateCart(cart);
          } else {
            console.log('Failed to update the cart.');
          }
        },
        error: function (xhr, status, error) {
          console.error('Error:', error);
        }
      });
    }
  });
});

/***************************************
 * Update mini cart
 ***************************************/
function updateCart(cart) {
  const currentLineItems = $('.side-mini-cart--body ul li');
  const lineItems = cart.lineItems;


  currentLineItems.each(function () {
    const currentItem = $(this);
    const lineItemId = currentItem.data('line-item-id');
    const foundItem = lineItems.find(lineItem => lineItem.id === lineItemId);

    if (!foundItem) {
      currentItem.addClass('remove');
      setTimeout(function () {
        currentItem.remove();
      }, 400);
    }
  });

  if (lineItems && lineItems.length > 0) {
    miniCartFooter.show();
    miniCartEmpty.hide();

    lineItems.forEach(function (lineItem) {
      const miniCartID = $('#line-item-' + lineItem.id);
      const cartID = $('#cart-item-' + lineItem.id);

      if (cartID.length) {
        const subtotalPrice = cartID.find('.subtotal');


        if (subtotalPrice.length) {
          subtotalPrice.html(lineItem.totalAsCurrency);
        }

      }

      if (miniCartID.length) {
        const subtotalPrice = miniCartID.find('.subtotal');
        const qtyInput = miniCartID.find('.qty-input');
        if (subtotalPrice.length) {
          subtotalPrice.html(lineItem.totalAsCurrency);
        }

        qtyInput.val(lineItem.qty);

      }
    });
  } else {
    miniCartFooter.hide();

    setTimeout(function() {
      miniCartEmpty.show().addClass('fade-in');
    }, 410)

  }

  const cartSubtotal = document.getElementById('cart-subtotal');
  const cartDiscount = document.getElementById('cart-discount');
  const cartTax = document.getElementById('cart-tax');
  const cartTotal = $('#cart-total');


  if (cartSubtotal) {
    console.log(cart);
    // cartSubtotal.html(cart.)
  }
  if (cartTotal.length > 0) {
    cartTotal.html(cart.totalPriceAsCurrency);
  }

  miniCartFooter.find('.total-price').html(cart.totalAsCurrency);

  // Update cart count
  miniCartCount.html(cart.totalQty)
}

/***************************************
 * Add product to cart
 ***************************************/

$('form.cart').on('submit', function (e) {
  e.preventDefault();


  let csrfTokenName = $('meta[name="csrf-token-name"]').attr('content');
  let csrfTokenValue = $('meta[name="csrf-token"]').attr('content');

  let formData = $(this).serialize();

  $.ajax({
    url: $(this).attr('action'),
    type: 'POST',
    data: formData + '&forceSave=true&' + csrfTokenName + '=' + csrfTokenValue,
    dataType: "json",
    headers: {
      'X-CSRF-Token': csrfTokenValue
    },
    success: function (response) {
      const cart = response.cart ?? false;
      if (cart) {
        console.log(cart.lineItems);
        updateCartBody();

        updateCart(cart);
      } else {
        console.log('Failed to update the cart.');
      }

    },
    error: function (xhr, status, error) {
      console.error('Error:', error);
      console.error('xhr:', xhr);
    }
  });
});

/***************************************
 * Update cart body
 ***************************************/
function updateCartBody() {
  let csrfTokenName = $('meta[name="csrf-token-name"]').attr('content');
  let csrfTokenValue = $('meta[name="csrf-token"]').attr('content');

  let $form = $('form.cart');

  $.ajax({
    url: '/actions/cart-module/cart/refresh-cart',
    type: 'POST',
    data: {
      forceSave: true,
      [csrfTokenName]: csrfTokenValue
    },
    dataType: "json",
    headers: {
      'X-CSRF-Token': csrfTokenValue
    },
    success: function (response) {

      if (response.cart) {
        miniCartBodyItems.html(response.cart);

        setTimeout(function () {
          toggleCart(true);
        }, 300)

        setTimeout(function() {
          $form[0].reset();
          $form.find('#variantInfo').html('');
        }, 500)
      }
    },
    error: function (xhr, status, error) {
      console.error('Error:', error);
      console.error('xhr:', xhr.responseText);
    }
  });
}


fetch('/actions/commerce/cart/get-cart', {
  headers: {
    'Accept': 'application/json',
  },
})
  .then(r => r.json())
  .then(console.log);
